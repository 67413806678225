// @flow
import * as React from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import { useLayoutState } from 'components/Layout';
import { isTablet } from 'components/Responsive';
import {ThemeBlackColor, ThemeWhiteColor} from 'components/Constants';
import NotFound from './NotFound';
import RotateIcon from 'assets/images/common/icon_rotate_device.svg';

import {
  useSessionState,
  useSessionDispatch,
  dispatchCountryCode,
  isValidColorCode,
  isValidModelCode
} from 'components/Session';
import VideoPlayer from 'components/VideoPlayer';
import Models from 'components/Models';

type Props = {};

export default function Main(props: Props): ?React.Element<any> {
  const {countryCode} = useParams();
  const session = useSessionState();
  const dispatch = useSessionDispatch();
  const layoutState = useLayoutState();
  const location = useLocation();
  const navigate = useNavigate();

  const locationRef = React.useRef();

  const [showModels, setShowModels] = React.useState(undefined);
  const [disableButtons, setDisableButtons] = React.useState(false);
  const [showBlackout, setShowBlackout] = React.useState(false);
  const [cmfWallRedirect, setCmfWallRedirect] = React.useState(undefined);
  const mobileFullScreen = React.useMemo(() => !layoutState.mobile ? false : showModels === false, [layoutState, showModels]);
  const enableScroll = layoutState.mobile ? ((mobileFullScreen || !layoutState.portrait) ? false : true) : (layoutState.tablet ? false : true); 

  const handleShowModels = React.useCallback(() => {
    if (disableButtons) {
      return;
    }
    setShowModels(true);
  }, [disableButtons]);

  const handleHideModels = React.useCallback(() => {
    if (disableButtons) {
      return;
    }
    setShowModels(false);
  }, [disableButtons]);

  const handleDisableButtons = React.useCallback((flag: boolean) => {
    setDisableButtons(flag);
  }, []);

  const handleShowBlackout = React.useCallback(() => {
    setShowBlackout(true);
  }, []);

  React.useEffect(() => {
    if (locationRef.current === location.pathname) {
      return;
    }

    locationRef.current = location.pathname;
    dispatchCountryCode(
      session,
      dispatch,
      countryCode ?? ''
    );
  }, [session, dispatch, countryCode, location]);

  React.useEffect(() => {
    if (cmfWallRedirect) {
      navigate(cmfWallRedirect, { replace: true })
    }
  }, [cmfWallRedirect, navigate]);

  React.useEffect(() => {
    function handlePageShow(e) {
      setShowBlackout(false);
    }
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
      setShowBlackout(false);
    }
  }, [])

  if (!isValidColorCode(session, session.colorCode) || (!isValidModelCode(session, session.modelCode) && session.modelCode !== undefined)) {
    if (session.settings.version === session.originSettings.version && session.error === undefined) {
      return null;
    }
    const paths = location.pathname.split('/');
    //console.log(paths, paths[paths.length - 1]);
    if (location.pathname.startsWith('/uk/') && (paths.length === 3 || (paths.length === 4 && paths[paths.length - 1] === ''))) {
      if (cmfWallRedirect === undefined) {
        setCmfWallRedirect(location.pathname.replace('/uk/', '/uk-b-cmfwall/'));
        return null;
      }
    }
    if (location.pathname.startsWith('/ch/france/') && (paths.length === 4 || (paths.length === 5 && paths[paths.length - 1] === ''))) {
      if (cmfWallRedirect === undefined) {
        setCmfWallRedirect(location.pathname.replace('/ch/france/', '/ch-french/'));
        return null;
      }
    }
    if (paths.length === 4 || (paths.length === 5 && paths[paths.length - 1] === '')) {
      const cCodes = ['/us/', '/ca/', '/cn/', '/de/', '/uk/', '/ch/', '/ae/', '/qa/', '/sa-riyadh/'];
      //console.log(paths);
      if (cCodes.some((value: string) => location.pathname.startsWith(value))) {
        //console.log(paths, `/${paths[1]}/${paths[3]}`);
        if (cmfWallRedirect === undefined) {
          setCmfWallRedirect(`/${paths[1]}/${paths[3]}`);
          return null;
        }
      }
    }
    return <NotFound />
  }

  if (isTablet() && layoutState.portrait) {
    return (<DivRotateDevice><img src={RotateIcon} alt={session.contents.ui?.rotate_your_device} /><span>{session.contents.text?.rotate_your_device}</span></DivRotateDevice>);
  }

  //console.log(enableScroll);

  return (
    <Wrapper showModels={showModels} mobileFullScreen={mobileFullScreen} enableScroll={enableScroll}>
      <BodyOverrideStyle bgColor={layoutState.mobile && !layoutState.portrait ? '#000000' : session.contents.ui?.player_bg_color} enableScroll={enableScroll}/>
      <VideoPlayer contents={session.contents} mobileFullScreen={mobileFullScreen} showModels={showModels} onShowModels={handleShowModels} onHideModels={handleHideModels} disableButtons={disableButtons} onShowBlackout={handleShowBlackout} />
      <Models show={showModels} contents={session.contents} onHideModels={handleHideModels} disableButtons={disableButtons} onDisableButtons={handleDisableButtons} enableScroll={enableScroll} onShowBlackout={handleShowBlackout} />
      <DivDissolve show={showBlackout} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: ${props => (props.theme.layout.mobile || props.theme.layout.tablet) ? '100%' : (props.theme.layout.width <= 1620 ? '100%' : '1620px')};
  height: 100%;
  margin: 0px ${props => (props.theme.layout.mobile || props.theme.layout.tablet) ? '0px' : (props.theme.layout.width <= 1620 ? '0px' : `${(props.theme.layout.width - 1620) * 0.5}px`)};
  
  display: ${props => props.enableScroll ? 'flex' : undefined};
  flex-direction: ${props => props.enableScroll ? 'column' : undefined};
`

const BodyOverrideStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.layout.mobile ? props.bgColor : ThemeBlackColor};
  }
  #root {
    background-color: ${props => props.theme.layout.mobile ? props.bgColor : ThemeBlackColor};
  }
`

const DivRotateDevice = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${ThemeBlackColor};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: genesis-head;
  color: ${ThemeWhiteColor};

  >img {
    margin-bottom: 40px;
  }
`

const DivDissolve = styled.div`
  z-index: 6;
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: #111111;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s;
  pointer-events: ${props => props.show ? 'auto' : 'none'};
`
