// @flow
import * as React from 'react';
import styled, {keyframes} from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useLayoutState } from "components/Layout";
import { useSessionState, isValidModelCode } from 'components/Session';
import { IsMobileAgent, IsNotMobileAgent, isMobile } from 'components/Responsive';
import {PageDotIcon, LeftAngleBracketL, RightAngleBracketL, RightArrow} from 'components/Icons';
import {RectButton, UnderlineButton} from 'components/BuildButton';
import { ThemeBlackColor, ThemeWhiteColor } from "./Constants";
//import Logo from 'assets/images/common/logo_genesis_150_30.png';

type Props = {
  children?: React.Node,
  contents?: Object,
  show?: boolean,
  onHideModels?: () => void,
  disableButtons: boolean,
  onDisableButtons: (boolean) => void,
  enableScroll: boolean,
  onShowBlackout?: () => void,
};

export default function Models(props: Props): React.Element<any> {
  const session = useSessionState();
  const wrapperRef = React.useRef<?React.ElementRef<'div'>>(null);
  const [showShadow, setShowShadow] = React.useState(false);
  const models = React.useMemo(() => {
    if (isValidModelCode(session, session.modelCode)) {
      return [session.modelCode]
    }
    return session.settings.model_codes.map((model) => session.settings.models[model]?.colors?.some(v => v.toLowerCase() === session.colorCode?.toLowerCase()) ? model : null).filter(v => v != null)
  }, [session]);

  const modelCounts = React.useMemo(() => {
    const count = models.length;
    const baseCount = 6;
    if (count > baseCount) {
      let arr = Array(Math.floor(count / baseCount) + ((count % baseCount) > 0 ? 1 : 0));
      arr.fill(baseCount);
      if (count % baseCount > 0) {
        arr[arr.length - 1] = count % baseCount;
      }
      return arr;
    }
    return [count];
  },[models]);

  React.useEffect(() => {
    function handleAnimationStart(e: Event) {
      if (e.target === wrapperRef.current) {
        props.onDisableButtons(true);
      }
    }
    function handleAnimationEnd(e: Event) {
      if (e.target === wrapperRef.current) {
        props.onDisableButtons(false);
      }
    }
    function handleScroll() {
      setShowShadow((wrapperRef.current?.scrollTop ?? 0) > 0);
    }
    const wrapperDiv = wrapperRef.current;
    if (wrapperDiv) {
      wrapperDiv.addEventListener("animationstart", handleAnimationStart);
      wrapperDiv.addEventListener("animationend", handleAnimationEnd);
      wrapperDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      wrapperDiv?.removeEventListener("animationstart", handleAnimationStart);
      wrapperDiv?.removeEventListener("animationend", handleAnimationEnd);
      wrapperDiv?.removeEventListener("scroll", handleScroll);
    }
  }, [wrapperRef, props]);

  return (
    <Wrapper ref={wrapperRef} show={props.show} bgColor={props.contents?.ui.player_bg_color} enableScroll={props.enableScroll}>
      <IsMobileAgent>
        <ModelCards models={models} contents={props.contents} buttonDisabled={props.disableButtons} onClickBuild={props.onShowBlackout} />
      </IsMobileAgent>
      <IsNotMobileAgent>
        <Carousel showThumbs={false} showArrows={true} showStatus={false} showIndicators={modelCounts.length > 1} renderIndicator={(handler, isSelected) => <ButtonPageDot onClick={handler} textColor={props.contents?.ui.player_text_color} selected={isSelected}><PageDotIcon /></ButtonPageDot>} renderArrowNext={(handler, show) => <ButtonArrowRight show={show} onClick={handler} textColor={props.contents?.ui.player_text_color}><RightAngleBracketL/></ButtonArrowRight>} renderArrowPrev={(handler, show) => <ButtonArrowLeft show={show} onClick={handler} textColor={props.contents?.ui.player_text_color}><LeftAngleBracketL/></ButtonArrowLeft>}>
        {modelCounts.map((val, idx) => {
          const beginIdx = idx > 0 ? modelCounts.slice(0, idx).reduce((sum, v) => sum + v) : 0;
          const endIdx = beginIdx + (modelCounts[idx] ?? 0);
          return (
            <ModelCards key={val} models={models.slice(beginIdx, endIdx)} contents={props.contents} buttonDisabled={props.disableButtons} onClickBuild={props.onShowBlackout} />
          );
        })}
        </Carousel>
      </IsNotMobileAgent>
      <IsMobileAgent>
        <DivScrollShadow showShadow={showShadow} />
      </IsMobileAgent>
    </Wrapper>
  );
}

type ModelCardsProps = {
  models: Array<?string>,
  contents?: Object,
  buttonDisabled?: bool,
  onClickBuild?: () => void,
}

function ModelCards(props: ModelCardsProps) {
  const session = useSessionState();
  const layoutState = useLayoutState();
  const mainRef = React.useRef(null);

  const modelsColumn = React.useMemo(() => session.modelCode ? 1 : (isMobile() ? 1 : 3), [session.modelCode]);
  const modelsRow = React.useMemo(() => session.modelCode ? 1 : 2, [session.modelCode]);

  const cardFonts = React.useMemo(() => {
    const baseWidth = isMobile() ? 400 : 1366;

    let titleSize = 24;
    let titleLineHeight = 26;
    let buttonSize = 14;
    let buttonLineHeight = 17.72;

    if (session.modelCode) {
      titleSize = 100;
      titleLineHeight = 120;
      buttonSize = 14;
      buttonLineHeight = 17.72;
    }

    return {
      titleSize: Math.round(titleSize / baseWidth * 1000) * 0.1,
      titleLineHeight: Math.round(titleLineHeight / baseWidth * 1000) * 0.1,
      buttonSize: Math.round(buttonSize / baseWidth * 1000) * 0.1,
      buttonLineHeight: Math.round(buttonLineHeight / baseWidth * 1000) * 0.1,
    }
  }, [session.modelCode]);
  const paddingWrapperD = React.useMemo(() => {
    return Math.floor((layoutState.height - layoutState.controllerHeight) * 0.085);
  }, [layoutState]);
  const colorCode = React.useMemo(() => session.colorCode?.toLowerCase(), [session]);

  const openInNewTab = React.useCallback((url: string): void => {
    if (props.buttonDisabled === true) {
      return;
    }
    const newWindow = window.open(url, '_self', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
    props.onClickBuild?.();
  }, [props]);

  const existsBuild = React.useCallback((model: string, colorCode?: string): boolean => {
    return colorCode !== undefined && session.settings.models[model].links?.[colorCode];
  }, [session.settings]);

  const handleBuild = React.useCallback((model: string, colorCode?: string) => {
    if (existsBuild(model, colorCode)) { 
      openInNewTab(session.settings.models[model].links[colorCode]);
    }
  }, [existsBuild, session.settings, openInNewTab]);

  React.useEffect(() => {
    mainRef.current = document.getElementsByTagName("main")[0];
  })

  return (
    <>
    <ModelWrapper modelCode={session.modelCode} bgColor={props.contents?.ui.player_bg_color} textColor={props.contents?.ui.player_text_color} paddingWrapperD={paddingWrapperD}>
        <ModelContainer modelCode={session.modelCode} countColumn={modelsColumn} countRow={modelsRow} textColor={props.contents?.ui.player_text_color}>
        {props.models.map((model => {
          if (!model) {
            return null;
          }
          return (<ModelCard key={model} modelCode={session.modelCode} countRow={modelsRow} fonts={cardFonts} textColor={props.contents?.ui.player_text_color} bgColor={props.contents?.ui.player_bg_color}>
            <div className='model-title-container'>
            {layoutState.tablet && session.modelCode && <span className='model-title'>{session.contents?.text.model_title}</span>}
            {session.settings.models[model].name}
            </div>
            <IsMobileAgent>
              <div className='model-image'>
                <img src={(process.env.PUBLIC_URL ?? '') + `/contents/${session.settings.country_code}/models/${model}/${model}-${session.colorCode?.toLowerCase() ?? ""}.png`} alt={`${model}`} onClick={session.modelCode && colorCode && session.settings.models[model].links?.[colorCode] ? () => openInNewTab(session.settings.models[model].links[colorCode]) : undefined} />                
              </div>
            </IsMobileAgent>
            <IsNotMobileAgent>
              <ModelImage className='model-image' modelCode={session.modelCode} imageUrl={(process.env.PUBLIC_URL ?? '') + `/contents/${session.settings.country_code}/models/${model}/${model}-${session.colorCode?.toLowerCase() ?? ""}.png`} onClick={session.modelCode && colorCode && session.settings.models[model].links?.[colorCode] ? () => openInNewTab(session.settings.models[model].links[colorCode]) : undefined}/>
            </IsNotMobileAgent>
            <IsMobileAgent>
              <div className='model-build'>
                {!session.modelCode && <BuildButton disabled={!existsBuild(model, colorCode)} fonts={cardFonts} modelCode={session.modelCode} textColor={props.contents?.ui.player_text_color} bgColor={props.contents?.ui.player_text_color.toLowerCase() === ThemeWhiteColor.toLowerCase() ? ThemeBlackColor : ThemeWhiteColor} onAnimationEnd={() => handleBuild(model, colorCode)}>
                  {existsBuild(model, colorCode) ? props.contents?.text.build_button : props.contents?.text.coming_soon_button}
                </BuildButton>
                }
                {session.modelCode && <BuildButton2 disabled={!existsBuild(model, colorCode)} fonts={cardFonts} modelCode={session.modelCode} textColor={props.contents?.ui.player_text_color} bgColor={props.contents?.ui.player_text_color.toLowerCase() === ThemeWhiteColor.toLowerCase() ? ThemeBlackColor : ThemeWhiteColor} onAnimationEnd={() => handleBuild(model, colorCode)}>
                  <span>{existsBuild(model, colorCode) ? props.contents?.text.build_button : props.contents?.text.coming_soon_button}</span> <RightArrow />
                </BuildButton2>
                }
              </div>
            </IsMobileAgent>
            <IsNotMobileAgent>
              <div className='model-build'>
              {!session.modelCode &&
                <BuildButton disabled={!existsBuild(model, colorCode)} fonts={cardFonts} modelCode={session.modelCode} textColor={props.contents?.ui.player_text_color} bgColor={props.contents?.ui.player_text_color.toLowerCase() === ThemeWhiteColor.toLowerCase() ? ThemeBlackColor : ThemeWhiteColor} onAnimationEnd={() => handleBuild(model, colorCode)}>
                  {existsBuild(model, colorCode) ? props.contents?.text.build_button : props.contents?.text.coming_soon_button}
                </BuildButton>
              }
              </div>
            </IsNotMobileAgent>
          </ModelCard>)
        }))}
        </ModelContainer>
        <IsNotMobileAgent>
          {!session.modelCode && <DivPagenation />}
        </IsNotMobileAgent>
      </ModelWrapper>
    </>
  );
}

const openAnimation = keyframes`
  0% { transform: translateY(0px); }
  100% { transform: translateY(-100%); }
`

const closeAnimation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0px); }
`

const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.theme.layout.tablet ? '100%' : undefined};
  z-index: 3;
  background-color: ${props => props.bgColor};

  pointer-events: ${props => props.enableScroll ? 'auto' : undefined};
  overflow-y: ${props => props.enableScroll ? 'auto' : undefined};
  
  animation-name: ${props => (props.show === undefined || props.theme.layout.mobile) ? undefined : (props.show ? openAnimation : closeAnimation)};
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  .slider-wrapper {
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }

  ul.control-dots {
    z-index: 4;
    height: 36px;
    margin: 0px;
    bottom: ${props => !props.theme.layout.mobile ? props.theme.layout.controllerHeight : 0}px;

    display: flex;
    justify-content: center;
    align-items: center;

    > :last-child {
      margin-right: 0px;
    }
  }
`

const ModelWrapper = styled.div`
  height: ${props => props.theme.layout.tablet ? `${props.theme.layout.height}px` : undefined};
  padding-top: ${props => props.theme.layout.mobile ? 24 : (props.modelCode ? '40': Math.floor((props.theme.layout.height - props.theme.layout.controllerHeight) * 0.085))}px;
  padding-left: ${props => props.theme.layout.width > props.theme.layout.videoWidth ? `${(props.theme.layout.width - props.theme.layout.videoWidth) * 0.5 + (props.theme.layout.mobile ? 20 : 40)}px` : `${props.theme.layout.mobile ? 20 : 40}px`};
  padding-right: ${props => props.theme.layout.width > props.theme.layout.videoWidth ? `${(props.theme.layout.width - props.theme.layout.videoWidth) * 0.5 + (props.theme.layout.mobile ? 20 : 40)}px` : `${props.theme.layout.mobile ? 20 : 40}px`};
  padding-bottom: ${props => !props.theme.layout.mobile ? props.theme.layout.controllerHeight : 0}px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  color: ${props => props.textColor};
`

const ModelContainer = styled.div`
  height: ${props => !props.theme.layout.mobile && props.modelCode ? '100%' : undefined};
  display: grid;

  grid-template-columns: repeat(${props => props.countColumn}, 1fr);
  grid-template-rows: ${props => (!props.theme.layout.tablet || props.countRow === 1) ? undefined : `repeat(${props.countRow}, ${Math.floor((props.theme.layout.height - props.theme.layout.controllerHeight) * 0.364)}px)`};
  row-gap: ${props => !props.theme.layout.tablet ? '24px' : `${Math.floor((props.theme.layout.height - props.theme.layout.controllerHeight) * 0.069)}px`}; 
  column-gap: ${props => props.theme.layout.mobile ? undefined : `${Math.floor(props.theme.layout.width * 0.04)}px`}; 

  > :last-child {
    margin-bottom: ${props => props.theme.layout.mobile ? '54px' : undefined};
  }
`

const ModelCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${props => props.theme.layout.mobile ? undefined : '100%'};
  /* margin-right: ${props => props.theme.layout.mobile ? undefined : '58px'};
  margin-bottom: ${props => props.theme.layout.mobile ? '24px' : undefined}; */
  margin-bottom: ${props => props.theme.layout.mobile ? '40px' : undefined};

  font-family: genesis-head-light;
  font-size: ${props => props.theme.layout.mobile ? '24px': `${props.fonts.titleSize}vw`};
  line-height: ${props => props.theme.layout.mobile ? '26px': `${props.fonts.titleLineHeight}vw`};

  .model-title-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .model-image {
    flex: 1;
    display: flex;
    align-items: center;
    width: ${props => !props.theme.layout.mobile && props.modelCode ? `calc(100% - 224px)` : undefined};
    margin-left: ${props => !props.theme.layout.mobile && props.modelCode ? '112px' : undefined};
    margin-right: ${props => !props.theme.layout.mobile && props.modelCode ? '112px' : undefined};

    > img {
      padding-top: ${props => props.theme.layout.mobile ? '30px' : undefined};
      padding-bottom: ${props => props.theme.layout.mobile ? '30px' : undefined};
    }
  }

  .model-build {
    width: 100%;
    padding-top: ${props => props.theme.layout.mobile || !props.modelCode ? undefined : `${Math.floor((props.theme.layout.height - props.theme.layout.controllerHeight) * 0.025)}px`};
  }

  .model-title {
    font-size: 30px;
    font-weight: 300;
    line-height: 37.98px;
    font-family: genesis-head;
  }
`

const BuildButton = styled(RectButton)`
  width: ${props => (!props.theme.layout.mobile && props.modelCode) ? '28vw': '100%'};
  font-size: ${props => props.theme.layout.mobile ? '14px': `${props.fonts.buttonSize}vw`};
  line-height: ${props => props.theme.layout.mobile ? '18px': `${props.fonts.buttonLineHeight}vw`};
  text-align: center;

  padding: ${props => props.theme.layout.mobile ? '14px': `${props.fonts.buttonSize}vw`};
`

const BuildButton2 = styled(UnderlineButton)`
  color: ${props => props.textColor};
  font-size: ${props => props.theme.layout.mobile ? '18px': `${props.fonts.buttonSize}vw`};
  line-height: ${props => props.theme.layout.mobile ? '18px': `${props.fonts.buttonLineHeight}vw`};

  padding: ${props => props.theme.layout.mobile ? '11px': `${props.fonts.buttonSize}vw`} 2px;

  display: flex;
  align-items: center;

  > svg {
    width: 10px;
    height: 16px;
    padding-left: 8px;

    > *[stroke] {
      stroke: ${props => props.textColor};
    }
    > *[fill] {
      fill: ${props => props.textColor};
    }
  }
`

const ModelImage = styled.div`
  width: 100%;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${props => props.modelCode ? '50% 50%' : '50% 50%'};
`

const DivPagenation = styled.div`
  height: 36px;
  margin-top: ${props => props.theme.layout.mobile ? '24px' : `${Math.floor((props.theme.layout.height - props.theme.layout.controllerHeight) * 0.069)}px`};
`

const ButtonPageDot = styled.button`
  padding: 4px;
  > svg {
    transform: translate3d(0, 0,0 );
    > circle {
      fill: ${props=> props.selected ? props.textColor : `${props.textColor}33`};
    }
  }
`

const ButtonArrowLeft = styled.button`
  width: 44px;
  height: 44px;

  opacity: ${props => props.show ? 1 : 0};
  position: absolute;
  z-index: 4;
  left: ${props => ((props.theme.layout.width - props.theme.layout.videoWidth) * 0.5) + (props.theme.layout.mobile ? 20 : 40) - 15}px;
  bottom: ${props => !props.theme.layout.mobile ? props.theme.layout.controllerHeight + 18 : 0}px;
  transform: translateY(50%);

  transition: opacity 0.25s;

  > svg {
    transform: translate3d(0, 0,0 );
    width: 14px;
    height: 36px;

    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }
`

const ButtonArrowRight = styled.button`
  width: 44px;
  height: 44px;
  opacity: ${props => props.show ? 1 : 0};
  position: absolute;
  z-index: 4;
  right: ${props => ((props.theme.layout.width - props.theme.layout.videoWidth) * 0.5) + (props.theme.layout.mobile ? 20 : 40) - 15}px;
  bottom: ${props => !props.theme.layout.mobile ? props.theme.layout.controllerHeight + 18 : 0}px;
  transform: translateY(50%);

  transition: opacity 0.25s;

  > svg {
    transform: translate3d(0, 0,0 );
    width: 14px;
    height: 36px;

    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }
`

const DivScrollShadow = styled.div`
  position: fixed;
  left: 0px;
  top: ${props => props.theme.layout.videoHeight + props.theme.layout.controllerHeight}px;
  width: 100%;
  height: 18px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: ${props => props.showShadow ? 0.1 : 0};
  transition: opacity 0.25s;
`

// const GoToTop = styled.button`
//   width: 100%;
//   background: #111111;
//   padding: 20px;
//   color: #ffffff;
//   text-align: right;

//   display: flex;
//   align-items: center;
//   justify-content: flex-end;

//   > svg {
//     margin-left: 6px;
//   }
// `

// const Footer = styled.div`
//   background: #111111;
//   border-top: 1px solid rgba(255, 255, 255, 0.05);
//   padding-top: 34px;
//   padding-left: 30px;
//   padding-right:30px;
//   padding-bottom: 55px;

//   font-family: genesis-text;
//   font-size: 12px;
//   line-height: 15px;
//   text-align: center;
//   color: #ffffff;

//   > p {
//     padding-top: 37px;
//     white-space: pre-line;  
//   }
// `