//@flow

type DeviceSize = {
  mobile: number,
  desktop: number,
}

const UseMobileNativePlayer = false;
const ControllerHeight: DeviceSize = {mobile: UseMobileNativePlayer ? 68 : 108, desktop:162};
const TopBarHeight: DeviceSize = {mobile: 0, desktop:0}; //57
const Defaults = {transitionDuration: 0.25};
const PreloadAssets = [""];
const ThemeBlackColor = "#000000";
const ThemeWhiteColor = "#FFFFFF";
const DesktopWidthMax = 1620;
const DesktopWidthMin = 768;

export {UseMobileNativePlayer, ControllerHeight, TopBarHeight, Defaults, PreloadAssets, ThemeBlackColor, ThemeWhiteColor, DesktopWidthMax, DesktopWidthMin};
